import { useSnackBars } from '@ev/eva-container-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GetRequestQuery,
  PostRequestBody,
  PostResponseData,
  PutRequestBody,
  PutResponseData,
  fetchClient,
} from 'api/rest/fetch-client';
import { Go3ActivityFeatures } from 'api/rest/models/go3/Go3ActivityFeatures';
import { useTranslation } from 'util/i18next';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

export type UpdateCallActivityRequest = PostRequestBody<'/api/v1/activities/call'>;
export type UpdateCallActivityResponse = PostResponseData<'/api/v1/activities/call'>;

export const useUpdateCallActivity = () => {
  const queryClient = useQueryClient();
  const { mutate: updateCallActivity, ...rest } = useMutation(
    ({ requestBody }: { requestBody: UpdateCallActivityRequest }) =>
      fetchClient
        .POST('/api/v1/activities/call', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { updateCallActivity, ...rest };
};

export type RetrieveGo3ActivityRequest = GetRequestQuery<'/api/v1/activities/go3'>;
export type RetrieveGo3ActivityResponse = PostResponseData<'/api/v1/activities/go3'>;

export const useRetrieveGo3Activities = (requestBody: RetrieveGo3ActivityRequest, disableQuery?: boolean) =>
  useQuery({
    queryKey: ['retrieveGo3Activities', requestBody],
    enabled: !!requestBody.go3ContactUtag && !disableQuery,
    queryFn: async () => {
      const response = await fetchClient.GET('/api/v1/activities/go3', {
        params: {
          query: requestBody,
        },
      });
      return response.data as Go3ActivityFeatures[];
    },
  });

export type linkActivityToLeadsRequest = PutRequestBody<'/api/v1/activities/{id}/link-leads'>;
export type linkActivityToLeadsResponse = PutResponseData<'/api/v1/activities/{id}/link-leads'>;
export const useLinkActivityToLeads = () => {
  const { t } = useTranslation(['activities']);
  const queryClient = useQueryClient();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { openSnackBar } = useSnackBars();
  const { mutate: linkActivityToLeads, ...rest } = useMutation(
    ({ requestBody, referenceId }: { requestBody: linkActivityToLeadsRequest; referenceId: string }) =>
      fetchClient
        .PUT('/api/v1/activities/{id}/link-leads', {
          params: {
            path: {
              id: referenceId,
            },
          },
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        openSnackBar(t('activities:linkLeadToActivity.success'), 'success');
        queryClient.invalidateQueries({ queryKey: ['activity'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
      onError: (error) => {
        openErrorSnackBar(t('activities:linkLeadToActivity.error'), error);
      },
    },
  );
  return { linkActivityToLeads, ...rest };
};
